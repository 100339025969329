import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: "",
    whichToShow: "keno",

    adminURL: "https://keno.retailtwo.playapicevirtual-h001.com/graphql",
    spinAdminURL: "https://spin.retailtwo.playapicevirtual-h001.com/graphql",
  },
  getters: {},
  mutations: {
    loginAdmin(state, admin) {
      state.admin = admin;
    },

    wichToSwitch(state, whichToShow) {
      state.whichToShow = whichToShow;
    },
  },
  actions: {
    loginAdmin(context, admin) {
      context.commit("loginAdmin", admin);
    },

    wichToSwitch(context, whichToShow) {
      context.commit("wichToSwitch", whichToShow);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
